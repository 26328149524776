@import "core/index.scss";
.section.home-topnav {
  padding: 2rem 0 1rem;
  border-top: .1rem solid $color-bg;
  
  .topnav-wrap {
    display: flex;
    justify-content: space-between;
  }

  .topnav-link {
    @include font-semibold;
    display: flex;
    align-items: center;

    i {
      font-size: .8rem;
      margin-bottom: .2rem;
    }

    span {
      position: relative;
      &:before {
        @include icon('deco');
        position: absolute;
        bottom: calc(100% - .3rem);
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 2.2rem;
      }
    }
    
    &:first-child {
      color: $color-blue;
      
      i {
        margin-right: .8rem;
      }
    }
    
    &:last-child {
      color: $color-primary;

      i {
        margin-left: .8rem;
      }
    }
  }
}